import { render, staticRenderFns } from "./EditInsuranceModal.vue?vue&type=template&id=2ce3f24a&"
import script from "./EditInsuranceModal.vue?vue&type=script&lang=ts&"
export * from "./EditInsuranceModal.vue?vue&type=script&lang=ts&"
import style0 from "./EditInsuranceModal.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { BButton } from 'bootstrap-vue'
import { BForm } from 'bootstrap-vue'
import { BFormCheckbox } from 'bootstrap-vue'
import { BFormGroup } from 'bootstrap-vue'
import { BFormInput } from 'bootstrap-vue'
installComponents(component, {BButton,BForm,BFormCheckbox,BFormGroup,BFormInput})
