













import { Component, Vue, Prop } from 'vue-property-decorator'
import { Money } from 'v-money'

@Component({
  components: {
    Money,
  },
})
export default class CurrencyInput extends Vue {
  @Prop({ type: Number, default: 0 })
  value?: number

  @Prop({ type: Boolean, default: null })
  state?: boolean | null

  get stateClass(): string {
    // add bootstrap input state class
    return this.state === false ? 'is-invalid' : ''
  }
}
