















import { Component, Vue, Prop } from 'vue-property-decorator'
import StripeBankAccount from '@/models/StripeBankAccount'
import PaymentItemActions from '@/components/settings/PaymentItemActions.vue'

@Component({
  components: {
    PaymentItemActions,
  },
})
export default class PaymentMethodItem extends Vue {
  @Prop({ type: Object, required: true })
  paymentMethod!: StripeBankAccount
}
