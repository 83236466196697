






















































import { Component, Vue } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import Modal from '@/components/Modal.vue'
import CurrencyInput from '@/components/inputs/CurrencyInput.vue'
import { validationStateMixin } from '@/util/validationState'
import { PPError } from '@/ppapi/PPError'

@Component({
  components: {
    Modal,
    CurrencyInput,
  },
  mixins: [validationMixin, validationStateMixin],
  computed: {
    ...mapGetters('asyncStatus', ['getError', 'isInProgress', 'hasSucceeded']),
    ...mapState('paymentMethods', ['bankAccountIdBeingVerified']),
  },
  validations: {
    deposit1: {
      required,
    },
    deposit2: {
      required,
    },
  },
})
export default class VerifyBankAccountModal extends Vue {
  hasSucceeded!: (key: string, id?: string) => boolean
  getError!: (key: string, id?: string) => PPError
  bankAccountIdBeingVerified!: string

  deposit1 = 0
  deposit2 = 0

  errorMessage = ''

  async onSubmit(ok: () => void) {
    this.$v.$touch()
    if (this.$v.invalid) return

    await this.$store.dispatch('paymentMethods/verify', {
      id: this.bankAccountIdBeingVerified,
      deposit1: this.deposit1.toFixed(2), // expects "0.30"
      deposit2: this.deposit2.toFixed(2),
    })

    if (this.getError('paymentMethods/verify', this.bankAccountIdBeingVerified)) {
      const err = this.getError('paymentMethods/verify', this.bankAccountIdBeingVerified)
      this.errorMessage = err?.data?.text || this.$t('Your bank account could not be verified')
    }

    if (this.hasSucceeded('paymentMethods/verify', this.bankAccountIdBeingVerified)) {
      const message = this.$t('Your bank account has been verified') as string
      this.$bvToast.toast(message, {
        variant: 'success',
      })
      ok()
    }
  }

  onHidden() {
    this.$v.$reset()

    this.deposit1 = 0
    this.deposit2 = 0

    this.errorMessage = ''

    this.$store.commit('paymentMethods/stopVerifyingBankAccount')
  }
}
