import { render, staticRenderFns } from "./InsurancesList.vue?vue&type=template&id=4ffe01dd&scoped=true&"
import script from "./InsurancesList.vue?vue&type=script&lang=ts&"
export * from "./InsurancesList.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ffe01dd",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { BListGroup } from 'bootstrap-vue'
import { BListGroupItem } from 'bootstrap-vue'
import { BMedia } from 'bootstrap-vue'
import IconPlus from '@/assets/icons/icon-plus.svg'
installComponents(component, {BListGroup,BListGroupItem,BMedia,IconPlus})
