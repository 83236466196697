






import { Component, Vue } from 'vue-property-decorator'
import getStripe from '@/util/stripe'

@Component
export default class StripeCardInput extends Vue {
  id = 'stripe-card-input-target'

  card: stripe.elements.Element | null = null
  errorMessage: string | null = null

  async mounted() {
    const result = await getStripe()
    if (!result.ok) return
    const { stripe } = result

    this.card = this.createCardElement(stripe)

    this.card.on('change', event => {
      if (!event) return

      if (event.error) {
        this.errorMessage = event.error.message || null
      } else {
        this.errorMessage = null
      }

      this.$emit('change', event)
    })

    this.card.mount(`#${this.id}`)
  }

  beforeDestroy() {
    this.destroyCardElement()
  }

  createCardElement(stripe: stripe.Stripe) {
    const elements = stripe.elements({
      fonts: [
        {
          // Note before changing:
          // 1) The CSS response needs a CORS policy (an access-control-allow-origin header)
          // 2) Stripe breaks when parsing the `text-rendering` property which is non-standard
          // 3) Stripe breaks when parsing  font-face src urls with "special" characters like ~
          // 4) Also note that the CSS URL needs to be HTTPS so testing the above can be difficult
          cssSrc: 'https://type.pillpack.com/flama/flama-book.css',
        },
      ],
    })
    const card = elements.create('card', {
      hidePostalCode: true,
      classes: {
        base: 'form-control',
        invalid: 'is-invalid',
      },
      style: {
        base: {
          fontFamily: 'Flama, Roboto, sans-serif',
          fontSize: '18px',
          fontWeight: '300',
          color: '#495057',
          '::placeholder': {
            color: '#c5c5c5',
          },
        },
        invalid: {},
      },
    })

    return card
  }

  destroyCardElement() {
    if (!this.card) return

    this.card.unmount()
    this.card.destroy()
  }
}
