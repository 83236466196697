







































































































































































































import { Component, Vue, Prop } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required, maxLength, helpers } from 'vuelidate/lib/validators'
import { mask, TheMask } from 'vue-the-mask'
import { format } from 'date-fns'

import Insurance from '@/models/Insurance'
import { PPError } from '@/ppapi/PPError'
import { rxBinValidation, insuranceStringFormat } from '@/util/validators/insuranceValidators'
import { validationStateMixin } from '@/util/validationState'
import { phoneNumberMask, usPhoneNumber } from '@/util/validators/phoneNumberValidator'
import UnreachableCaseError from '@/util/UnreachableCaseError'
import Modal from '@/components/Modal.vue'
import AlertMessage from '@/components/AlertMessage.vue'

const TODAY = format(new Date(), 'YYYY-MM-DD')

// 20 would be the expected limit, but sometimes IDs have an extra dash in them before the patient code
const MAX_ID_LENGTH = 21

// max length of fields for insurance payers per data dictionary
const RXBIN_LENGTH = 6
const MAX_RXGROUP_LENGTH = 15
const MAX_RXPCN_LENGTH = 10

@Component({
  components: { Modal, AlertMessage, TheMask },
  mixins: [validationMixin, validationStateMixin],
  computed: {
    ...mapGetters('asyncStatus', ['getError', 'isInProgress', 'hasSucceeded']),
  },
  directives: { mask },
  validations: {
    insurance: {
      number: {
        required,
        insuranceStringFormat,
        maxLength: maxLength(MAX_ID_LENGTH),
      },
      rxBin: {
        required,
        rxBinValidation,
      },
      rxPCN: {
        required,
        insuranceStringFormat,
        maxLength: maxLength(MAX_RXPCN_LENGTH),
      },
      rxGroup: {
        required,
        insuranceStringFormat,
        maxLength: maxLength(MAX_RXGROUP_LENGTH),
      },
      phoneNumber: {
        optionalPhone: val => {
          return !helpers.req(val) || usPhoneNumber(val)
        },
      },
    },
  },
})
export default class EditInsurance extends Vue {
  insurance: Insurance = new Insurance()
  hasSucceeded!: (key: string) => boolean
  getError!: (key: string) => PPError | null
  isInProgress!: (key: string) => boolean
  phoneNumberMask: typeof phoneNumberMask = phoneNumberMask
  knownStart: boolean = true

  readonly RXBIN_LENGTH = RXBIN_LENGTH
  readonly MAX_ID_LENGTH = MAX_ID_LENGTH
  readonly MAX_RXPCN_LENGTH = MAX_RXPCN_LENGTH
  readonly MAX_RXGROUP_LENGTH = MAX_RXGROUP_LENGTH
  readonly rxBinMask = '#'.repeat(RXBIN_LENGTH)

  @Prop({ type: String, required: true })
  modalId!: string

  created() {
    this.insurance.effectiveStartDate = TODAY
  }

  close() {
    this.$bvModal.hide(this.modalId)
  }

  onHide() {
    this.reset()
  }

  reset() {
    this.insurance = new Insurance()
    this.insurance.effectiveStartDate = TODAY
    this.$store.commit('asyncStatus/reset', { key: 'insurances/create' })
    this.$v.$reset()
  }

  updateKnownStart(value: boolean) {
    this.knownStart = value
    this.insurance.effectiveStartDate = this.knownStart ? TODAY : undefined
  }

  get phoneNumberValidityClass() {
    let className = null
    const validity =
      this.$v.insurance && this.$v.insurance.phoneNumber && this.$v.insurance.phoneNumber.$dirty
        ? !this.$v.insurance.phoneNumber.$invalid
        : null

    // we want to send null to the class list on our input component when there is no value yet so that the input looks neither valid or invalid.
    switch (validity) {
      case true:
        className = 'is-valid'
        break
      case false:
        className = 'is-invalid'
        break
      case null:
        className = null
        break
      default:
        throw new UnreachableCaseError(validity)
    }

    return className
  }

  async createInsurance(): Promise<void> {
    this.$v.$touch()

    if (!this.$v.$invalid) {
      await this.$store.dispatch('insurances/create', this.insurance)

      if (this.hasSucceeded('insurances/create')) {
        this.$bvToast.toast(this.$t('Your insurance has been added') as string, {
          title: this.$t('Insurance Added') as string,
          variant: 'success',
        })
        this.close()
      }
    }
  }
}
