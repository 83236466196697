








import { Component, Vue, Prop } from 'vue-property-decorator'
import USState from '@/util/USState'

@Component
export default class USStateInput extends Vue {
  @Prop(String)
  value?: string

  @Prop(String)
  id?: string

  @Prop({ type: Boolean, default: null })
  state?: boolean

  @Prop(Boolean)
  autofocus?: boolean

  @Prop({ type: String, default: 'state' })
  name?: string

  @Prop({ type: String, default: 'name' })
  textField?: string

  @Prop({ type: String, default: 'abbreviation' })
  valueField?: string

  @Prop({ default: undefined })
  nullValue: undefined | null

  get options(): USState[] {
    return USState.allStates
  }

  get props() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { nullValue, ...rest } = this.$props
    return rest
  }
}
