





















import { Component, Vue } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import Account from '@/models/Account'
import Card from '@/components/Card.vue'

@Component({
  components: {
    Card,
  },
  computed: {
    ...mapGetters('user', ['account']),
  },
})
export default class PaymentFailedCard extends Vue {
  account?: Account

  get lastFailedPaymentInfo() {
    return this.account ? this.account.lastFailedPaymentInfo : null
  }
}
