





















import { Component, Vue, Prop } from 'vue-property-decorator'
import StripeCard from '@/models/StripeCard'
import PaymentItemActions from '@/components/settings/PaymentItemActions.vue'
import PaymentTypeImage from '@/components/PaymentTypeImage.vue'

@Component({
  components: {
    PaymentTypeImage,
    PaymentItemActions,
  },
})
export default class PaymentMethodItem extends Vue {
  @Prop({ type: Object, required: true })
  paymentMethod!: StripeCard
}
