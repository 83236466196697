




























































































import { Component, Vue } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required, maxLength, minLength, numeric } from 'vuelidate/lib/validators'
import getStripe from '@/util/stripe'
import Modal from '@/components/Modal.vue'
import { validationStateMixin } from '@/util/validationState'

const MAX_LINE_LENGTH = 35

const ROUTING_NUMBER_LENGTH = 9

@Component({
  components: {
    Modal,
  },
  mixins: [validationMixin, validationStateMixin],
  computed: {
    ...mapState('user', ['me']),
    ...mapGetters('paymentMethods', ['hasCreditCard']),
    ...mapGetters('asyncStatus', ['isInProgress', 'hasSucceeded', 'getError']),
  },
  validations: {
    name: { required, maxLength: maxLength(MAX_LINE_LENGTH) },
    accountNumber: {
      required,
      numeric,
    },
    routingNumber: {
      required,
      numeric,
      minLength: minLength(ROUTING_NUMBER_LENGTH),
      maxLength: maxLength(ROUTING_NUMBER_LENGTH),
    },
  },
})
export default class AddPaymentCardModal extends Vue {
  hasSucceeded!: (key: string) => boolean
  getError!: (key: string) => Error | undefined

  hasCreditCard!: boolean

  id = 'add-bank-account-modal'

  isCompanyAccount = false

  name = ''
  routingNumber = ''
  accountNumber = ''

  stripeErrorMessage = ''
  routingNumberLength = ROUTING_NUMBER_LENGTH

  get title() {
    return this.$t('Add bank account')
  }

  get bankDetails() {
    return {
      country: 'US',
      currency: 'USD',
      routing_number: this.routingNumber,
      account_number: this.accountNumber,
      account_holder_name: this.name,
      account_holder_type: this.isCompanyAccount ? 'company' : 'individual',
    }
  }

  onHidden() {
    this.$v.$reset()

    this.isCompanyAccount = false

    this.name = ''
    this.routingNumber = ''
    this.accountNumber = ''

    this.stripeErrorMessage = ''

    this.$store.commit('asyncStatus/reset', { key: 'paymentMethods/addBankAccount' })
  }

  async onSubmit(ok: () => void) {
    this.$v.$touch()

    if (this.$v.$invalid) {
      return
    }

    const result = await getStripe()
    if (!result.ok) return
    const { stripe } = result

    const { token, error } = await stripe.createToken('bank_account', this.bankDetails)
    if (error) {
      this.stripeErrorMessage = error.message || (this.$t('stripe.error.unknown') as string)
    }
    if (!token) return

    await this.$store.dispatch('paymentMethods/addBankAccount', {
      token,
    })

    if (this.hasSucceeded('paymentMethods/addBankAccount')) {
      const message = this.toastMessage()
      this.$bvToast.toast(message, {
        title: this.$t('Bank account added') as string,
        variant: 'success',
      })
      ok()
    }
  }

  toastMessage() {
    if (!this.hasCreditCard) {
      return this.$t('creditCard.promptToAdd') as string
    }

    return this.$t('Your bank account was successfully saved') as string
  }

  get errorSubmitting() {
    const err: any = this.getError('paymentMethods/addBankAccount')
    if (err && err.data && err.data.text) {
      return err.data.text
    }

    if (this.stripeErrorMessage) {
      return this.stripeErrorMessage
    }

    return null
  }
}
