






















































import { Component, Vue, Prop } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import StripeCard from '@/models/StripeCard'
import StripeBankAccount from '@/models/StripeBankAccount'
import AddPaymentCardModal from '@/components/settings/AddPaymentCardModal.vue'
import Modal from '@/components/Modal.vue'
import UnreachableCaseError from '@/util/UnreachableCaseError'

@Component({
  components: { AddPaymentCardModal, Modal },
  computed: {
    ...mapGetters('paymentMethods', ['allVerifiedMethods', 'hasMultipleDefaults']),
  },
})
export default class PaymentItemActions extends Vue {
  @Prop({ type: Object, required: true })
  paymentMethod!: StripeCard | StripeBankAccount

  allVerifiedMethods!: (StripeCard | StripeBankAccount)[]
  hasMultipleDefaults!: boolean
  deleteDefaultModalOpen: boolean = false

  get deleteDefaultModalTitle() {
    switch (this.paymentMethod.constructor) {
      case StripeCard:
        return this.$t('Delete card')
      case StripeBankAccount:
        return this.$t('Delete account')
      default:
        throw new UnreachableCaseError(this.paymentMethod as never)
    }
  }

  get needsVerification() {
    return this.paymentMethod instanceof StripeBankAccount && !this.paymentMethod.verified
  }

  openVerificationModal() {
    this.$store.commit('paymentMethods/startVerifyingBankAccount', this.paymentMethod.id)
    this.$bvModal.show('verify-bank-account-modal')
  }

  setAsDefault() {
    this.$store.dispatch('paymentMethods/makeDefault', { id: this.paymentMethod.id })
  }

  deletePaymentMethod() {
    if (this.paymentMethod.isDefaultMethod) {
      if (this.allVerifiedMethods.length === 1) {
        this.$bvModal.show(`delete-and-add-card-modal-${this.paymentMethod.id}`)
        return
      } else if (!this.hasMultipleDefaults) {
        this.deleteDefaultModalOpen = true
        return
      }
    }
    this.$store.dispatch('paymentMethods/delete', { id: this.paymentMethod.id })
  }
}
