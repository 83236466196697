import { render, staticRenderFns } from "./PaymentView.vue?vue&type=template&id=013b1530&scoped=true&"
import script from "./PaymentView.vue?vue&type=script&lang=ts&"
export * from "./PaymentView.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "013b1530",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { BCardSubTitle } from 'bootstrap-vue'
import { BCardTitle } from 'bootstrap-vue'
import IconInsuranceCard from '@/assets/icons/icon-insurance-card.svg'
installComponents(component, {BCardSubTitle,BCardTitle,IconInsuranceCard})
