










































import { mapState, mapGetters } from 'vuex'

import { Component, Vue } from 'vue-property-decorator'

import Account from '@/models/Account'
import Insurance from '@/models/Insurance'

import PageHeader from '@/components/PageHeader.vue'
import Card from '@/components/Card.vue'
import HelpSearchCard from '@/components/HelpSearchCard.vue'
import InsurancesList from '@/components/settings/InsurancesList.vue'
import PaymentFailedCard from '@/components/settings/PaymentFailedCard.vue'
import PaymentMethodList from '@/components/settings/PaymentMethodList.vue'
import HsaFsaPaymentMethodList from '@/components/settings/HsaFsaPaymentMethodList.vue'
import AddPaymentCardModal from '@/components/settings/AddPaymentCardModal.vue'
import AddBankAccountModal from '@/components/settings/AddBankAccountModal.vue'
import VerifyBankAccountModal from '@/components/settings/VerifyBankAccountModal.vue'

@Component({
  components: {
    Card,
    PageHeader,
    HelpSearchCard,
    InsurancesList,
    PaymentFailedCard,
    PaymentMethodList,
    HsaFsaPaymentMethodList,
    AddPaymentCardModal,
    AddBankAccountModal,
    VerifyBankAccountModal,
  },
  computed: {
    ...mapGetters('user', ['account']),
    ...mapState('insurances', ['insurances']),
    ...mapGetters('paymentMethods', ['fsaHsa', 'nonFsaHsa']),
    ...mapGetters('asyncStatus', ['getError']),
  },
})
export default class PaymentView extends Vue {
  getError!: (key: string) => Error

  account?: Account
  insurances!: Insurance[]

  get hasError() {
    return !!(this.getError('insurances/load') || this.getError('paymentMethods/load'))
  }

  created(): void {
    this.$store.dispatch('insurances/load')
    this.$store.dispatch('paymentMethods/load')
  }

  get showOverdueNotice() {
    return this.account && this.account.showDashboardOverdueNotice
  }

  get breadcrumbs() {
    return [
      {
        text: this.$t('pages.settings.title'),
        to: { name: 'settings' },
      },
      {
        text: this.$t('pages.settings.payment.title'),
        to: { name: 'payment' },
      },
    ]
  }
}
