var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Modal',{attrs:{"id":_vm.id,"title":_vm.title,"loading":_vm.isInProgress('paymentMethods/addBankAccount'),"confirm-before-close":""},on:{"hidden":_vm.onHidden},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var ok = ref.ok;
var cancel = ref.cancel;
return [_c('b-button',{staticClass:"flex-fill mb-1",attrs:{"variant":"primary","data-testid":"submit"},on:{"click":function($event){return _vm.onSubmit(ok)}}},[_vm._v(" "+_vm._s(_vm.$t('Add bank account'))+" ")]),_c('b-button',{staticClass:"flex-fill mb-1",attrs:{"variant":"cancel","data-testid":"cancel"},on:{"click":cancel}},[_vm._v(" "+_vm._s(_vm.$t('Cancel'))+" ")])]}}])},[_c('b-alert',{attrs:{"show":_vm.errorSubmitting,"variant":"danger"}},[_vm._v(" "+_vm._s(_vm.errorSubmitting)+" ")]),_c('b-form-group',{attrs:{"label":_vm.$t('Account Holder Name'),"invalid-feedback":_vm.invalidFeedbackMessage(_vm.$v.name, { field: _vm.$t('name') }),"label-for":"edit-name__input","data-testid":"edit-name-field"}},[_c('b-form-input',{attrs:{"id":"edit-name__input","state":_vm.isValid(_vm.$v.name),"trim":"","type":"text","name":"name","spellcheck":"false"},on:{"change":function($event){return _vm.$v.name.$touch()}},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1),_c('b-form-group',{attrs:{"label":_vm.$t('Routing number'),"invalid-feedback":_vm.invalidFeedbackMessage(_vm.$v.routingNumber, {
        field: _vm.$t('routing number'),
        min: _vm.routingNumberLength,
        max: _vm.routingNumberLength,
      }),"label-for":"edit-routing-number__input","data-testid":"edit-routing-number-field"}},[_c('b-form-input',{attrs:{"id":"edit-routing-number__input","state":_vm.isValid(_vm.$v.routingNumber),"trim":"","type":"text","name":"name","spellcheck":"false"},on:{"change":function($event){return _vm.$v.routingNumber.$touch()}},model:{value:(_vm.routingNumber),callback:function ($$v) {_vm.routingNumber=$$v},expression:"routingNumber"}})],1),_c('b-form-group',{attrs:{"label":_vm.$t('Account number'),"invalid-feedback":_vm.invalidFeedbackMessage(_vm.$v.accountNumber, { field: _vm.$t('account number') }),"label-for":"edit-account-number__input","data-testid":"edit-account-number-field"}},[_c('b-form-input',{attrs:{"id":"edit-account-number__input","state":_vm.isValid(_vm.$v.accountNumber),"trim":"","type":"text","name":"name","spellcheck":"false"},on:{"change":function($event){return _vm.$v.accountNumber.$touch()}},model:{value:(_vm.accountNumber),callback:function ($$v) {_vm.accountNumber=$$v},expression:"accountNumber"}})],1),_c('b-form-group',[_c('b-form-checkbox',{model:{value:(_vm.isCompanyAccount),callback:function ($$v) {_vm.isCompanyAccount=$$v},expression:"isCompanyAccount"}},[_vm._v(" "+_vm._s(_vm.$t('This is a company account'))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }