export default class USState {
  readonly name: string
  readonly abbreviation: string
  readonly shippable: boolean

  private constructor(name: string, abbreviation: string, shippable: boolean = true) {
    this.name = name
    this.abbreviation = abbreviation
    this.shippable = shippable
  }

  static getStateByAbbreviation(str: string): USState | undefined {
    return this.allStates.find(el => el.abbreviation === str)
  }

  static readonly allStates = [
    new USState('Alabama', 'AL'),
    new USState('Alaska', 'AK'),
    new USState('Arizona', 'AZ'),
    new USState('Arkansas', 'AR'),
    new USState('California', 'CA'),
    new USState('Colorado', 'CO'),
    new USState('Connecticut', 'CT'),
    new USState('Delaware', 'DE'),
    new USState('District of Columbia', 'DC'),
    new USState('Florida', 'FL'),
    new USState('Georgia', 'GA'),
    new USState('Hawaii', 'HI', false),
    new USState('Idaho', 'ID'),
    new USState('Illinois', 'IL'),
    new USState('Indiana', 'IN'),
    new USState('Iowa', 'IA'),
    new USState('Kansas', 'KS'),
    new USState('Kentucky', 'KY'),
    new USState('Louisiana', 'LA'),
    new USState('Maine', 'ME'),
    new USState('Maryland', 'MD'),
    new USState('Massachusetts', 'MA'),
    new USState('Michigan', 'MI'),
    new USState('Minnesota', 'MN'),
    new USState('Mississippi', 'MS'),
    new USState('Missouri', 'MO'),
    new USState('Montana', 'MT'),
    new USState('Nebraska', 'NE'),
    new USState('Nevada', 'NV'),
    new USState('New hampshire', 'NH'),
    new USState('New Jersey', 'NJ'),
    new USState('New Mexico', 'NM'),
    new USState('New York', 'NY'),
    new USState('North Carolina', 'NC'),
    new USState('North Dakota', 'ND'),
    new USState('Ohio', 'OH'),
    new USState('Oklahoma', 'OK'),
    new USState('Oregon', 'OR'),
    new USState('Pennsylvania', 'PA'),
    new USState('Rhode Island', 'RI'),
    new USState('South Carolina', 'SC'),
    new USState('South Dakota', 'SD'),
    new USState('Tennessee', 'TN'),
    new USState('Texas', 'TX'),
    new USState('Utah', 'UT'),
    new USState('Vermont', 'VT'),
    new USState('Virginia', 'VA'),
    new USState('Washington', 'WA'),
    new USState('West Virginia', 'WV'),
    new USState('Wisconsin', 'WI'),
    new USState('Wyoming', 'WY'),
  ]
}
