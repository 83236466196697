var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Modal',{attrs:{"id":_vm.modalId,"title":_vm.$t('Add new insurance'),"loading":_vm.isInProgress('insurances/create'),"data-testid":"insurance-modal","centered":""},on:{"hide":_vm.onHide}},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.createInsurance($event)}}},[_c('b-form-group',{staticClass:"edit-insurance",attrs:{"id":"edit-number-fieldset","invalid-feedback":_vm.invalidFeedbackMessage(_vm.$v.insurance.number, { field: 'ID Number', max: _vm.MAX_ID_LENGTH }),"label":_vm.$t('ID Number'),"description":_vm.$t('Sometimes labelled Member or Enrollee ID'),"label-for":"edit-number__input","data-testid":"number-field"}},[_c('b-form-input',{attrs:{"id":"edit-number__input","state":_vm.isValid(_vm.$v.insurance.number),"type":"text","name":"number","autofocus":"","trim":"","spellcheck":"false"},on:{"change":function($event){return _vm.$v.insurance.number.$touch()}},model:{value:(_vm.insurance.number),callback:function ($$v) {_vm.$set(_vm.insurance, "number", $$v)},expression:"insurance.number"}})],1),_c('b-form-group',{staticClass:"edit-insurance",attrs:{"id":"edit-rxbin-fieldset","invalid-feedback":_vm.invalidFeedbackMessage(_vm.$v.insurance.rxBin, {
          field: 'RxBin',
          min: _vm.RXBIN_LENGTH,
          max: _vm.RXBIN_LENGTH,
        }),"label":_vm.$t('Rx Bin'),"description":_vm.$t('A 6 digit number'),"label-for":"edit-rxbin__input","data-testid":"rxbin-field"}},[_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.rxBinMask),expression:"rxBinMask"}],attrs:{"id":"edit-rxbin__input","state":_vm.isValid(_vm.$v.insurance.rxBin),"type":"text","name":"rxBin","trim":"","spellcheck":"false"},on:{"change":function($event){return _vm.$v.insurance.rxBin.$touch()}},model:{value:(_vm.insurance.rxBin),callback:function ($$v) {_vm.$set(_vm.insurance, "rxBin", $$v)},expression:"insurance.rxBin"}})],1),_c('b-form-group',{staticClass:"edit-insurance",attrs:{"id":"edit-rxpcn-fieldset","invalid-feedback":_vm.invalidFeedbackMessage(_vm.$v.insurance.rxPCN, { field: 'RxPCN', max: _vm.MAX_RXPCN_LENGTH }),"label":_vm.$t('Rx PCN'),"label-for":"edit-rxpcn__input","data-testid":"rxpcn-field"}},[_c('b-form-input',{attrs:{"id":"edit-rxpcn__input","state":_vm.isValid(_vm.$v.insurance.rxPCN),"type":"text","name":"rxPCN","trim":"","spellcheck":"false"},on:{"change":function($event){return _vm.$v.insurance.rxPCN.$touch()}},model:{value:(_vm.insurance.rxPCN),callback:function ($$v) {_vm.$set(_vm.insurance, "rxPCN", $$v)},expression:"insurance.rxPCN"}})],1),_c('b-form-group',{staticClass:"edit-insurance",attrs:{"id":"edit-rxgroup-fieldset","invalid-feedback":_vm.invalidFeedbackMessage(_vm.$v.insurance.rxGroup, {
          field: 'Rx Group',
          max: _vm.MAX_RXGROUP_LENGTH,
        }),"label":_vm.$t('Rx Group'),"description":_vm.$t('Sometimes labelled RxGrp'),"label-for":"edit-rxgroup__input","data-testid":"rxgroup-field"}},[_c('b-form-input',{attrs:{"id":"edit-rxgroup__input","state":_vm.isValid(_vm.$v.insurance.rxGroup),"type":"text","name":"rxGroup","trim":"","spellcheck":"false"},on:{"change":function($event){return _vm.$v.insurance.rxGroup.$touch()}},model:{value:(_vm.insurance.rxGroup),callback:function ($$v) {_vm.$set(_vm.insurance, "rxGroup", $$v)},expression:"insurance.rxGroup"}})],1),_c('b-form-group',{staticClass:"edit-insurance edit-start-group",attrs:{"id":"edit-start-fieldset","label-for":"edit-start__input","data-testid":"start-field"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',[_vm._v(_vm._s(_vm.$t('When does this coverage start?')))]),_c('b-form-checkbox',{staticClass:"edit-start-checkbox",attrs:{"id":"start-checkbox","value":false,"unchecked-value":true,"name":"start-date","data-testid":"edit-start-checkbox"},on:{"change":_vm.updateKnownStart}},[_vm._v(" "+_vm._s(_vm.$t('I don’t know'))+" ")])],1)]},proxy:true}])},[(_vm.knownStart)?_c('b-form-input',{attrs:{"id":"edit-start__input","type":"date","name":"Start Date","data-testid":"edit-start-input","trim":"","spellcheck":"false"},model:{value:(_vm.insurance.effectiveStartDate),callback:function ($$v) {_vm.$set(_vm.insurance, "effectiveStartDate", $$v)},expression:"insurance.effectiveStartDate"}}):_vm._e()],1),_c('b-form-group',{staticClass:"edit-insurance",attrs:{"id":"edit-phone-fieldset","invalid-feedback":_vm.invalidFeedbackMessage(_vm.$v.insurance.phoneNumber, {
          field: 'Phone Number',
        }),"label":_vm.$t('Insurance Phone Number'),"description":_vm.$t('Optional'),"label-for":"edit-phone-input","data-testid":"phone-field"}},[_c('the-mask',{ref:"inputMask",staticClass:"form-control",class:_vm.phoneNumberValidityClass,attrs:{"id":"edit-phone-input","mask":_vm.phoneNumberMask(_vm.insurance.phoneNumber),"placeholder":"555-555-5555","type":"tel","spellcheck":"false"},nativeOn:{"change":function($event){return _vm.$v.$touch()}},model:{value:(_vm.insurance.phoneNumber),callback:function ($$v) {_vm.$set(_vm.insurance, "phoneNumber", $$v)},expression:"insurance.phoneNumber"}})],1),_c('AlertMessage',{attrs:{"error":_vm.getError('insurances/create')}})],1),_c('template',{slot:"modal-footer"},[_c('b-button',{staticClass:"col",attrs:{"disabled":_vm.$v.$invalid || _vm.isInProgress('insurances/create'),"variant":"primary","data-testid":"submit"},on:{"click":_vm.createInsurance}},[_vm._v(" "+_vm._s(_vm.$t('Add'))+" ")]),_c('b-button',{staticClass:"col",attrs:{"variant":"cancel","data-testid":"cancel"},on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.$t('Cancel'))+" ")])],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }