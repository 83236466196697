






















































































































































import { Component, Vue, Prop } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import { validationMixin } from 'vuelidate'
import { requiredIf } from 'vuelidate/lib/validators'
import { mask } from 'vue-the-mask'
import getStripe from '@/util/stripe'
import User from '@/models/User'
import Address from '@/models/Address'
import Modal from '@/components/Modal.vue'
import StripeCardInput from '@/components/inputs/StripeCardInput.vue'
import USStateInput from '@/components/inputs/USStateInput.vue'
import { validationStateMixin } from '@/util/validationState'
import StripeCard from '@/models/StripeCard'
import StripeBankAccount from '@/models/StripeBankAccount'
import UnreachableCaseError from '../../util/UnreachableCaseError'

function billingDifferentFromShipping(this: AddPaymentCardModal) {
  return !this.useCurrentShippingAddress
}

@Component({
  components: {
    Modal,
    USStateInput,
    StripeCardInput,
  },
  mixins: [validationMixin, validationStateMixin],
  directives: { mask },
  computed: {
    ...mapState('user', ['me']),
    ...mapGetters('addresses', ['currentShippingAddress']),
    ...mapGetters('paymentMethods', ['hasCreditCard']),
    ...mapGetters('asyncStatus', ['isInProgress', 'hasSucceeded', 'getError']),
  },
  validations: {
    name: { required: requiredIf(billingDifferentFromShipping) },
    street: { required: requiredIf(billingDifferentFromShipping) },
    city: { required: requiredIf(billingDifferentFromShipping) },
    state: { required: requiredIf(billingDifferentFromShipping) },
    zipcode: { required: requiredIf(billingDifferentFromShipping) },
  },
})
export default class AddPaymentCardModal extends Vue {
  @Prop({ type: String, default: 'add-payment-card-modal' })
  id!: string

  @Prop({ type: Boolean, default: false })
  isFsaHsa!: boolean

  @Prop({ type: Boolean, default: false })
  isDeleting!: boolean

  @Prop(Object)
  methodToDelete?: StripeCard | StripeBankAccount

  isInProgress!: (key: string) => boolean
  hasSucceeded!: (key: string) => boolean
  getError!: (key: string) => Error | undefined

  stripeIsComplete: boolean = false
  stripeIsEmpty: boolean = true
  addressesLoaded: Promise<void> | null = null
  makeDefault = false
  useCurrentShippingAddress = true

  name = ''
  street = ''
  street2 = ''
  city = ''
  state = ''
  zipcode = ''

  stripeErrorMessage = ''

  me!: User
  currentShippingAddress!: Address | null
  hasCreditCard!: boolean
  isCreatingFsaHsa: boolean = this.isFsaHsa

  get title() {
    if (this.isDeleting) {
      return this.$t('Delete card')
    } else if (this.isCreatingFsaHsa) {
      return this.$t('Add HSA/FSA card')
    }

    return this.$t('Add debit or credit card')
  }

  get deleteMethodType() {
    if (!this.methodToDelete) return this.$t('payment method')
    switch (this.methodToDelete.constructor) {
      case StripeCard:
        return this.$t('card')
      case StripeBankAccount:
        return this.$t('bank account')
      default:
        throw new UnreachableCaseError(this.methodToDelete as never)
    }
  }

  onShown() {
    this.addressesLoaded = this.$store.dispatch('addresses/get')
  }

  handleStripeInputChange(event: stripe.elements.ElementChangeResponse) {
    this.stripeIsComplete = event.complete
    this.stripeIsEmpty = event.empty
  }

  get cardDetails() {
    if (!this.currentShippingAddress) {
      return {
        name: this.name,
        address_line1: this.street,
        address_line2: this.street2 || '',
        address_city: this.city,
        address_state: this.state,
        address_zip: this.zipcode,
      }
    }
    const address = this.currentShippingAddress

    return {
      name: this.me.fullName,
      address_line1: address.street,
      address_line2: address.street2 || '',
      address_city: address.city,
      address_state: address.state,
      address_zip: address.zipcode,
    }
  }

  get hasEnteredData() {
    return !!(!this.stripeIsEmpty || this.makeDefault)
  }

  get useShippingAddressCheckboxLabel() {
    const street = this.currentShippingAddress
      ? this.currentShippingAddress.street
      : this.$t('addresses.loadingStreetMessage')

    return this.$t('Billing address is same as shipping address ({shippingAddress})', {
      shippingAddress: street,
    })
  }

  onHidden() {
    this.$v.$reset()
    this.makeDefault = false
    this.useCurrentShippingAddress = true
    this.stripeIsComplete = false
    this.addressesLoaded = null

    this.name = ''
    this.street = ''
    this.street2 = ''
    this.city = ''
    this.state = ''
    this.zipcode = ''

    this.stripeErrorMessage = ''

    this.$store.commit('asyncStatus/reset', { key: 'paymentMethods/addCard' })
  }

  async onSubmit(ok: () => void) {
    this.$v.$touch()
    if (this.$v.invalid) return
    if (!this.stripeIsComplete) return

    const cardElement = (this.$refs.stripeInput as any).card
    if (!cardElement) return

    const result = await getStripe()
    if (!result.ok) return
    const { stripe } = result

    await this.addressesLoaded
    if (!this.cardDetails) return

    const { token, error } = await stripe.createToken(cardElement, this.cardDetails)
    if (error) {
      this.stripeErrorMessage = error.message || (this.$t('stripe.error.unknown') as string)
    }
    if (!token) return

    await this.$store.dispatch('paymentMethods/addCard', {
      token,
      options: {
        isFsaHsa: this.isCreatingFsaHsa,
        makeDefault: this.isDeleting || this.makeDefault,
      },
    })

    if (this.hasSucceeded('paymentMethods/addCard')) {
      if (this.isDeleting && this.methodToDelete) {
        await this.$store.dispatch('paymentMethods/delete', { id: this.methodToDelete.id })
      }
      const message = await this.toastMessage(stripe, cardElement)
      this.$bvToast.toast(message, {
        title: this.$t('Card added') as string,
        variant: 'success',
      })

      ok()
    }
  }

  get submitting() {
    return this.isInProgress('paymentMethods/addCard')
  }

  async toastMessage(stripe: stripe.Stripe, cardElement: stripe.elements.Element) {
    const defaultMessage = this.$t('Your card was successfully saved') as string

    if (this.hasCreditCard) {
      return defaultMessage
    }

    const { paymentMethod } = await stripe.createPaymentMethod('card', cardElement)
    const isCreditCard =
      paymentMethod && paymentMethod.card && paymentMethod.card.funding === 'credit'

    if (!isCreditCard) {
      return this.$t('creditCard.promptToAdd') as string
    }

    return defaultMessage
  }

  get errorSubmitting() {
    const err: any = this.getError('paymentMethods/addCard')
    if (err && err.data && err.data.text) {
      return err.data.text
    }

    if (this.stripeErrorMessage) {
      return this.stripeErrorMessage
    }

    return null
  }
}
