/**
 * validates an rxbin is 6 digits
 * valid rxBin for testing: '003858',
 *
 * @public
 * @param {string} rxBin
 * @returns {boolean}
 */
export function rxBinValidation(rxBin: string): boolean {
  return !!rxBin && /^\d{6}$/.test(rxBin)
}

/**
 * Checks that an insurance card string contains only valid characters
 * including dashes and some special chars.
 *
 * Some of the third-party APIs and printed materials like labels cannot
 * use many unicode chars. This validation should work for most cases.
 *
 * @public
 * @param {string} str
 * @returns {boolean}
 */
export function insuranceStringFormat(str: string): boolean {
  if (!str || !str.trim().length) {
    return false
  }

  return !!str.match(/^[A-Za-z0-9 \-'.\u2019\u00c0-\u00d6\u00d8-\u00f6\u00f8-\u00ff]+$/)
}
