












































import { Component, Vue } from 'vue-property-decorator'
import { mapGetters } from 'vuex'

import StripeCard from '@/models/StripeCard'
import StripeBankAccountListItem from '@/components/settings/StripeBankAccountListItem.vue'
import StripeCardListItem from '@/components/settings/StripeCardListItem.vue'
import SkeletonListGroup from '@/components/SkeletonListGroup.vue'
import Card from '@/components/Card.vue'

@Component({
  components: {
    Card,
    SkeletonListGroup,
    StripeCardListItem,
    StripeBankAccountListItem,
  },
  computed: {
    ...mapGetters('asyncStatus', ['isInProgress', 'getError']),
    ...mapGetters('paymentMethods', ['fsaHsa']),
  },
})
export default class HsaFsaPaymentMethodList extends Vue {
  getError!: (status: string) => boolean
  isInProgress!: (key: string, id?: string) => boolean

  fsaHsa!: StripeCard[]

  get paymentMethods() {
    return this.fsaHsa
  }

  get isLoading(): boolean {
    return !this.hasPaymentMethods && this.isInProgress('paymentMethods/load')
  }

  get hasError(): boolean {
    return !!this.getError('paymentMethods/load')
  }

  get hasPaymentMethods(): boolean {
    return !!(this.paymentMethods && this.paymentMethods.length)
  }

  isItemLoading(paymentMethodId: string) {
    return this.isInProgress('paymentMethods/delete', paymentMethodId)
  }
}
