







































import { Component, Vue, Prop } from 'vue-property-decorator'
import { mapGetters } from 'vuex'

import Insurance from '@/models/Insurance'
import InsuranceItem from '@/components/settings/InsuranceItem.vue'
import Card from '@/components/Card.vue'
import SkeletonListGroup from '@/components/SkeletonListGroup.vue'
import EditInsuranceModal from '@/components/settings/EditInsuranceModal.vue'

@Component({
  components: {
    Card,
    EditInsuranceModal,
    InsuranceItem,
    SkeletonListGroup,
  },
  computed: {
    ...mapGetters('asyncStatus', ['isInProgress', 'getError']),
  },
})
export default class InsuranceList extends Vue {
  isInProgress!: (status: string) => boolean
  getError!: (status: string) => Error

  @Prop({ type: Array, required: true })
  insurances!: Insurance[]

  get isLoading(): boolean {
    return !this.foundInsurances && this.isInProgress('insurances/load')
  }

  get hasError(): boolean {
    return !!this.getError('insurances/load')
  }

  get foundInsurances(): boolean {
    return !!this.insurances && !!this.insurances.length
  }
}
